export default {
    'no-file': {
        title: 'Sorry, we couldn’t find you.',
        description:
            'Unfortunately we couldn’t locate your credit history from Experian. This could be because you don’t have any credit history. Why not come back and register once you’ve built up your credit file?',
        subtitle: null,
        secondaryDescription: 'You can find out how to do this in our Learn section.',
        buttonLabel: 'Get started',
        url: 'https://www.clearscore.com/learn',
    },
    'no-match': {
        title: 'Sorry, we couldn’t match your details.',
        description:
            'It looks like the phone number you provided doesn’t match the one we have from Experian. Please check the number you’ve provided is correct. ',
        subtitle: null,
        secondaryDescription:
            'If your number has changed, we’re working on a fix to make this work. Please come back and try to register soon.',
        buttonLabel: 'Return to homepage',
        url: 'https://www.clearscore.com',
    },
    wait: {
        title: 'Sorry!',
        description:
            'Maintaining the security of the information on your credit report is very important. We use a series of checks to protect this information. Unfortunately for security reasons you have been locked out of accessing your credit report.',
        subtitle: "We can't retrieve your credit report and score today.",
        secondaryDescription:
            'Please wait 24 hours before logging into your account and continuing the registration process. We are sorry for any inconvenience and thanks for trying ClearScore.',
        buttonLabel: 'Return to homepage',
        url: 'https://www.clearscore.com',
    },
    generic: {
        title: 'Oops',
        description:
            'Something has gone wrong. We’re sorry but it looks like we won’t be able to complete your account today.',
        subtitle: 'What now?',
        secondaryDescription:
            'We’re doing our best to get everything up and running again. Please check back again later.',
        buttonLabel: 'Return to homepage',
        url: 'https://www.clearscore.com',
    },
};
