import type { VFC } from 'react';
import React from 'react';
import Container from '@clearscore/rainbow.container';

import GenericMessage from './components/generic-message';
import lang from './lib/lang';

interface Props {
    match?: {
        params?: {
            errorType?: keyof typeof lang;
        };
    };
}

const Error: VFC<Props> = ({ match }) => {
    const language = match?.params?.errorType ? lang[match.params.errorType] ?? lang.generic : lang.generic;

    return (
        <Container
            theme={Container.Theme.LIGHT}
            size={Container.Size.HUGE}
            top={Container.Spacing.HUGE}
            bottom={Container.Spacing.HUGE}
        >
            <GenericMessage
                title={language.title}
                description={language.description}
                subtitle={language.subtitle}
                secondaryDescription={language.secondaryDescription}
                buttonLabel={language.buttonLabel}
                handleButtonPress={(): void => window.location.assign(language.url)}
            />
        </Container>
    );
};

export default Error;
