import type { VFC } from 'react';
import React from 'react';
import Text from '@clearscore/rainbow.text';
import Button from '@clearscore/rainbow.button';
// eslint-disable-next-line import/no-internal-modules
import type { IButtonProps } from '@clearscore/rainbow.button/src/types';

import type lang from '../../lib/lang';
import styles from './index.module.css';

type Props = Partial<
    typeof lang['no-file' | 'no-match' | 'generic' | 'wait'] & {
        handleButtonPress: IButtonProps['onClick'];
    }
>;

const GenericMessage: VFC<Props> = ({
    title,
    subtitle,
    description,
    secondaryDescription,
    buttonLabel,
    handleButtonPress,
}) => (
    <section>
        <Text.H1>{title}</Text.H1>
        {description ? <Text.Body1>{description}</Text.Body1> : null}

        <div className={styles.container}>
            {subtitle ? <Text.H3>{subtitle}</Text.H3> : null}
            {secondaryDescription ? <Text.Body1>{secondaryDescription}</Text.Body1> : null}
        </div>

        {handleButtonPress && buttonLabel ? (
            <Button size={Button.sizes.LARGE} onClick={handleButtonPress}>
                {buttonLabel}
            </Button>
        ) : null}
    </section>
);

export default GenericMessage;
